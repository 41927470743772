<template>
  <v-card class="embed-login">
    <v-card-title class="d-flex align-center justify-center py-7">
      <router-link
        to="/"
        class="d-flex align-center"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-height="50px"
          max-width="185px"
          alt="logo"
          contain
        ></v-img>
      </router-link>
    </v-card-title>
    <LoginForm />
    <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
      <span class="me-2">
        {{ $t('no-account') }}
      </span>
      <router-link
        to="#"
        @click.native.prevent="openRegisterPage"
      >
        {{ $t('register') }}
      </router-link>
    </v-card-text>
  </v-card>
</template>

<script>

import LoginForm from '@/modules/auth/components/LoginForm.vue'

export default {
  components: {
    LoginForm,
  },

  created() {
    const channel = new BroadcastChannel('logged-in')
    channel.onmessage = () => {
      window.close()
    }
  },

  methods: {
    openRegisterPage() {
      window.open('/auth/register', '_blank')
      window.close()
    },
  },

}
</script>
<style>
  body::-webkit-scrollbar {
    display: none;
  }
</style>
